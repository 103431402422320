<script>
export default {
	props: {
		popupConfig: Object,
	},
	data() {
		return {
			name: null,
			email: null,
			valid: false,
			rules: {
				required: (value) => !!value || 'Requerido.',
				email: (value) => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'e-mail no valido.'
				},
			},
		}
	},
	methods: {
		send() {
			if (!this.valid) return
		},
	},
}
</script>

<template>
	<v-form @submit.prevent="send" v-model="valid" class="w100">
		<div class="d-flex justify-center py-2 px-6" v-if="popupConfig.ctaSelector === 'btn-text-url'">
			<!--			<v-col cols="12" sm="6">-->
			<!--				<v-text-field-->
			<!--					v-model="email"-->
			<!--					:rules="[rules.required, rules.email]"-->
			<!--					class="mt-3 primary"-->
			<!--					:color="$vars.primarytext"-->
			<!--					dense-->
			<!--					label="email"-->
			<!--					hide-details-->
			<!--					outlined-->
			<!--				></v-text-field>-->
			<!--			</v-col>-->
			<SafeLink :to="popupConfig.ctaUrl" class="w100">
				<Button class="cta w100" style="border-radius: 10px" @click="$emit('ctaClick')">
					<span class="font-1">{{ popupConfig.ctaBtnText }}</span>
				</Button>
			</SafeLink>
		</div>
		<v-row class="py-2 px-6" dense v-else>
			<v-col cols="12">
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="name"
							class="primary"
							:color="$vars.primarytext"
							:rules="[rules.required]"
							dense
							label="nombre"
							hide-details
							outlined
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="email"
							class="primary"
							:color="$vars.primarytext"
							:rules="[rules.required, rules.email]"
							dense
							label="email"
							hide-details
							outlined
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<Button class="cta mt-3 w100" style="border-radius: 10px" @click="send"
					>{{ popupConfig.ctaBtnText }}
				</Button>
			</v-col>
		</v-row>
	</v-form>
</template>

<style></style>
